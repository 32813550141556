// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Introduction from "../components/Section/Introduction";
import Offerings from "../components/Section/Offerings";
import TestimonialsSection from "../components/Section/Testimonials";
import Blogs from "../components/Section/Blog";
import Community from "../components/Section/Community";
import Trust from "../components/Section/Trust";
import useScrollTracker from "../utils/scrolltracking";
import TrackEvent from "../utils/events";

const Home = () => {
    useScrollTracker('HomePage');
    const offeringsRef = useRef(null);

    const location = useLocation();
    useEffect(() => {
        // Extract query parameters
        const queryParams = new URLSearchParams(location.search);
        const campaign = queryParams.get("campaign");

        // Check for specific campaign and redirect
        if (campaign === "dbs") {
            TrackEvent('dbs_campaign_website')
            window.location.href = "https://www.dbs.com/in/getaccount/bankwithus/?utm_source=Finurja";
        }
    }, [location]);

    return (
        <div className="w-screen min-h-screen">
            <Introduction />
            <Offerings offeringsRef={offeringsRef} />
            <TestimonialsSection />
            <Blogs />
            <Community />
            <Trust />
        </div>
    )
}

export default Home;