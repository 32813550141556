// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

const images = {
    logoUrl: 'https://finurja-website-images.s3.ap-south-1.amazonaws.com/logo.png',
    googlePlayIconUrl: 'https://finurja-website-images.s3.ap-south-1.amazonaws.com/google-play.png',
    appStoreIconUrl: 'https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/app_store.png',
    qr: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/footer/qr.png",
    instagram: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/footer/instagram.png",
    meta: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/footer/meta.png",
    twiter: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/footer/twitter.png",
    whatsapp: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/footer/whatsapp.png",
    introBg: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/bg.svg",
    introBgMobile: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/mobile-bg.svg",
    introAssets: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/assets.png",
    introAssetsWeb: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/assets_web.png",
    introAssetsWithoutDbs: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/introduction/assets_without_dbs.png",
    avoidLosses: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/avoid-losses.png",
    betterFinanciallyPrepared: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/better-financially-prepared.png",
    higherReturns: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/higher-returns.png",
    moneyTracking: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/money-tracking.png",
    moreSavings: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/more-savings.png",
    seekFinancialAdvise: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/seek-financial-advise.png",
    featureArrow: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/offerings/feature-arrow.png",
    leftArrow: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/left-arrow.png",
    rightArrow: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/right-arrow.png",
    testinomial1: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/testimonial1.png",
    testinomial2: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/testimonial2.png",
    testinomial3: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/testimonial3.png",
    rating: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/rating.png",
    testinomialsBg: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/testimonials/testimonials-bg.svg",
    blogImage: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/home/blogs/blog-image.png",
    about: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/about/about.png",
    charges: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/about/charges.png",
    security: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/about/security.png",
    support: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/about/support.png",
    trust: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/about/trust.png",
    offeringsBg: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/bg.png",
    offeringsMobileBg: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/mobile-bg.png",
    offeringsPoint: "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/offering_point.png"
};

export default images;
